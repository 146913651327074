// import React, { useContext, useState } from 'react'
// import { GlobalDataContext } from '../../context/context'
// import CardProductBusqueda from './CardProductBusqueda'
// const Busqueda = () => {
//     const { rpdata } = useContext(GlobalDataContext)

//     const [cardProduct, setCardProduct] = useState('')

//     const [search, setSearch] = useState('')

//     //funcion de busqeda
//     const searcher = (e) => {
//         setSearch(e.target.value)
//         setCardProduct('')
//         // console.log(e.target.value)
//     }


//     const resultFilter = !search ? rpdata?.works : rpdata?.works?.filter((datos) => datos?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))


//     const handleSelectChange = (e) => {
//         setCardProduct(e.target.value)
//         setSearch('')

//     }

//     return (
//         <div className='h-full w-4/5 mx-auto '>

//             <div>
//                 <div className="flex flex-col-reverse md:flex-row md:gap-0 gap-5 py-20">

//                     <select className={`flex-shrink-0 z-10 py-2.5 px-4 text-sm font-medium text-start text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100  ${!search ? 'block' : 'hidden md:inline-flex'}`} onChange={handleSelectChange}>
//                         <option selected disabled>Platillos</option>
//                         {
//                             rpdata?.dbServices?.map((item, index) => {
//                                 return (
//                                     <option key={index} value={item.name}>{item.name}</option>
//                                 )
//                             })
//                         }
//                     </select>

//                     <div className="relative w-full">
//                         <input
//                             type="search"
//                             className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
//                             placeholder="Buscar Platillos ..."
//                             required
//                             value={search}
//                             onChange={searcher}
//                         />
//                         <div type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">
//                             <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
//                             <span className="sr-only">Search</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className='flex h-full'>
//                 <div className='w-full h-full'>
//                     <div className='w-full h-full flex justify-center flex-wrap gap-10'>
//                         {


//                             cardProduct === '' ?
//                                 resultFilter?.map((item, index) => {
//                                     return (

//                                         <CardProductBusqueda
//                                             key={index}
//                                             imagen={item.gallery?.[0]}
//                                             name={item.name}
//                                             price={item.description}
//                                             phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-","")}
//                                         />


//                                     )
//                                 })
//                                 :
//                                 <div className='flex flex-wrap gap-3 p-5'>
//                                     {
//                                         // eslint-disable-next-line array-callback-return
//                                         rpdata?.works?.map((item, index) => {
//                                             const expert = item?.service;
//                                             switch (cardProduct) {
//                                                 case `${expert}`:
//                                                     return (

//                                                         <CardProductBusqueda
//                                                             key={index}
//                                                             imagen={item.gallery?.[0]}
//                                                             name={item.name}
//                                                             price={item.description}
//                                                             phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-","")}
//                                                             />
//                                                     )
//                                                 default:
//                                                 // do nothing
//                                             }
//                                         })
//                                     }
//                                 </div>
//                         }
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Busqueda
import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from '../global/boton/ButtonContent';

const ContentMenu = (cntService) => {
    const { rpdata } = useContext(GlobalDataContext);

    let classAnterior = "md:flex-row-reverse"

    return (
        <div className=''>
            {
                rpdata?.dbServices?.slice(4,8).map((serv, index) => {
                    let classes = index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
                    let nuevaClass = classAnterior === "md:flex-row-reverse" ? "md:flex-row" : "md:flex-row-reverse"
                    classes = nuevaClass
                    classAnterior = classes;
                    return (
                        <div className='pb-20'>
                            <div key={index} className={`pb-3 mb-10 border-b-2 flex flex-col-reverse justify-between items-center ${classes}`}>
                                <div>
                                    <h2 className='text-center capitalize'>{serv.name}</h2>
                                    <ButtonContent btnLink='./Contact' btnName={'Order Now'} />
                                </div>

                                <img
                                    src={serv?.description[0]?.img}
                                    alt='services images'
                                    className='w-[150px] h-[150px] rounded-full shadow-lg'
                                />
                            </div>
                            <div>
                                {
                                    rpdata?.works?.map((item, index) => {
                                        if (item.service === serv.name) {
                                            return (
                                                <div key={index} className='w-[90%] mx-auto flex items-center'>
                                                    <p className='w-[75%] md:w-[80%] border-dotted border-b-2 capitalize font-semibold text-[18px] mb-5'>{item.name}</p>
                                                    <p className='w-[25%] md:w-[20%] text-end md:text-start text-[#8E101F] font-semibold'> ${item.description}</p>
                                                </div>
                                            )
                                        }
                                        return null
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ContentMenu